.rc-tooltip-inner {
  background-color: #ffffff;
  color: #000000;
  border-radius: 0px;
  padding: 18px 20px;
  max-width: 190px;
}

.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-top {
  padding: 5px 0 20px 0;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: #ffffff;
  bottom: 10px;
  margin-left: -10px;
  border-width: 10px 10px 0;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  margin-left: -20px;
}

.rc-tooltip {
  opacity: 1;
  z-index: 5;
}
