.rc-tooltip-inner {
  background-color: #ffffff;
  color: #000000;
  border-radius: 0px;
  padding: 18px 20px;
  max-width: 190px;
}

.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-top {
  padding: 5px 0 20px 0;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: #ffffff;
  bottom: 10px;
  margin-left: -10px;
  border-width: 10px 10px 0;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  margin-left: -20px;
}

.rc-tooltip {
  opacity: 1;
  z-index: 5;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

